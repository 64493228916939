import { createReducerContext } from 'react-use';
import update from 'immutability-helper';

import _ from 'lodash';

export { default as PrModal } from './PrModal';
export { default as OutItemModal } from './OutItemModal';
export { default as BannerModal } from './BannerModal';
export { default as PhoneNumberModal } from './PhoneNumberModal';
export { default as LackyModal } from './LackyModal';
export { default as BonusModal } from './BonusModal';
export { default as ForgotModal } from './ForgotModal';
export { WithdrawNone } from './WithdrawNone';
export { TradeUrlSave } from './TradeUrlSave';
export { AuthSpecial } from './AuthSpecial';
export { WinDemoModal } from './WinDemoModal';
export { LuckyModalWin } from './lucky-modal-win/LuckyModalWin';
export { PopularModal } from './popular-modal/PopularGames';
export { GiveawaysModal } from './gitveaways/giveawaysModal';
export {
  PromoModal1,
  PromoModal2,
  PromoModal3,
  PromoModal4,
  PromoModal7,
  PromoModal8,
  PromoModal9,
  PromoModal10,
  PromoModal11,
  PromoModal12,
  PromoModal13,
} from './promoModals';
export { WheelPrize } from './WheelPrize';
export { DistributionModal } from './DistributionModal';
export { ExitPopup } from './ExitPopup';
export { DistributionWinModal } from './DistributionWinModal';
export { DistributionUserWinModal } from './DistributionUserWinModal';

import { initialState } from './initialState';

type Action = {
  type: string;
  modalName?: string;
  props?: any;
};

const reducer = (state: any, action: Action) => {
  const { type, props, modalName } = action;

  const modal = state[modalName];

  switch (type) {
    case 'updateProps': {
      return update(state, {
        [modalName]: {
          $merge: props,
        },
      });
    }
    case 'onClose': {
      if (_.isFunction(modal?.onClose)) {
        modal.onClose();
      }

      return update(state, {
        [modalName]: {
          $set: {
            ...modal,
            isOpen: false,
          },
        },
      });
    }
    case 'onShow': {
      return update(state, {
        [modalName]: {
          $set: {
            isOpen: true,
            ...props,
          },
        },
      });
    }
    case 'onCloseAll': {
      return Object.keys(state).reduce((accumulator, key) => {
        const modal = state[key];
        return {
          ...accumulator,
          [key]: {
            ...modal,
            isOpen: false,
          },
        };
      }, {});
    }
    default:
      return state;
  }
};

export const [useSharedState, ModalsProvider] = createReducerContext(
  reducer,
  initialState,
);
