import { FunctionComponent, useState } from 'react';
// import styles from './styles.module.scss';
// import Button from '@/app/components/ui/button';
// import Close from '@/app/components/icons/close';
// import Steam from '@/app/components/icons/steam';
// import Vk from '@/app/components/icons/vk';
// import Fb from '@/app/components/icons/fb';
// import Google from '@/app/components/icons/google';
import Steam from './steam';
import Google from './google';
import Vk from './vk';
import './sign.scss';
import Close from '../closeIcon';
import { Button } from '~shared/frontend/ui/button/button';
import { useConfig } from '~frontend/components/hooks';

const SignIn: FunctionComponent<any> = ({ onClose }) => {
  const [isClosing, setIsClosing] = useState(false);
  const getConfig = useConfig();

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 500); // Длительность анимации
  };

  return (
    <div
      className={`sign_in ${isClosing ? 'closing' : ''}`}
      onClick={handleClose}
    >
      <div className="sign_in_body" onClick={(e) => e.stopPropagation()}>
        <div className="sing_in_header">
          Choose method to sign in
          <div className="sing_in_close" onClick={handleClose}>
            <Close />
          </div>
        </div>
        <Button
          icon={<Steam />}
          text="Steam"
          icon_direction="left"
          className={`btn1_blue_light steam_btn`}
          href={'/auth/steam'}
        />
        <div className="sign_in_devider">
          <span></span>
          Or
          <span></span>
        </div>
        <div className="sign_in_socials">
          {!getConfig?.isEU && (
            <Button
              is_icon={true}
              icon={<Vk />}
              className={`sign_in_socials_btn vk_btn`}
              href={'/auth/vk'}
            />
          )}
          {/* <Button
            is_icon={true}
            icon={<Fb />}
            className={`${styles.sign_in_socials_btn} ${styles.fb_btn}`}
          /> */}
          {getConfig?.isEU && (
            <Button
              is_icon={true}
              icon={<Google />}
              className={`sign_in_socials_btn google_btn}`}
              href={'/auth/google'}
            />
          )}
        </div>
        <div className="sign_in_description">
          By registering, you agree to our Terms of Use and Privacy Policy and
          confirm that you are over 18 years of age.
        </div>
      </div>
    </div>
  );
};

export default SignIn;
