export default function Done({ ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.2188L18.8906 6.28125C19.3125 5.90625 19.9219 5.90625 20.2969 6.28125C20.6719 6.70312 20.6719 7.3125 20.2969 7.6875L9.70312 18.2812C9.32812 18.7031 8.67188 18.7031 8.29688 18.2812L4.07812 14.1094C3.70312 13.6875 3.70312 13.0781 4.07812 12.7031C4.5 12.3281 5.10938 12.3281 5.48438 12.7031L9 16.2188Z"
        fill="#089408"
      />
    </svg>
  );
}
