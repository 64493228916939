export default function ToTop(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6C9 5.4375 9.46875 5.01562 9.98438 5.01562H18C18.5625 5.01562 18.9844 5.4375 18.9844 6V14.0156C18.9844 14.5312 18.5625 15 18 15C17.4375 15 17.0156 14.5312 17.0156 14.0156V8.39062L6.09375 19.3125C5.71875 19.6875 5.10938 19.6875 4.6875 19.3125C4.3125 18.8906 4.3125 18.2812 4.6875 17.9062L15.6094 6.98438H9.98438C9.46875 6.98438 9 6.5625 9 6Z"
        fill="#7BA8DF"
      />
    </svg>
  );
}
