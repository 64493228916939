import { useState, useEffect, FunctionComponent } from 'react';
import './modalWrapperStyle.scss';
import Close from '../../closeIcon';

const ModalWrapper: FunctionComponent<any> = ({
  children,
  isOpen,
  onClose,
  title,
  className,
  titleAlign = 'left',
  titleColor = '#ffffff',
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // useEffect(() => {
  //   if (isOpen) {
  //     setIsVisible(true);
  //     setIsClosing(false);
  //   }
  // }, [isOpen]);

  // const handleClose = () => {
  //   setIsClosing(true);
  //   setTimeout(() => {
  //     setIsVisible(false);
  //     onClose();
  //   }, 500); // Длительность анимации
  // };

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 500); // Длительность анимации
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 500);
  };

  if (!isVisible && !isOpen) return null;

  return (
    <div className="modal1" onClick={handleClose}>
      <div
        className={`modal1_body ${className || ''} ${
          isClosing ? 'closing' : ''
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {title && (
          <div className={`modal1_header align_${titleAlign}`}>
            <h2 style={{ color: titleColor }}>{title}</h2>
            <div className="modal1_close" onClick={handleClose}>
              <Close />
            </div>
          </div>
        )}
        {!title && (
          <div className="modal1_close_only" onClick={handleClose}>
            <Close />
          </div>
        )}
        <div className="modal1_content">{children}</div>
      </div>
    </div>
  );
};

export default ModalWrapper;
