import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from '../hooks';

import { CountDown } from '~components/CountDown';
import { usePromo } from '~frontend/components/pay/payment-right/PromoCode';
import Modal from '../Modal';

import PopupImg from '../../../scss/images/popup-img.png';

export const PromoModal10: FunctionComponent = () => {
  const modalName = 'PromoModal10';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{
    promoCode: string;
    promoCodeTimeOut: number;
    percent: number;
  }>(modalName);
  const [, , setPromoCode] = usePromo();

  const _onClose = () => {
    setPromoCode(props?.promoCode, props?.promoCodeTimeOut);

    onClose();
  };

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={482} modalName={modalName}>
      <div className="box-modal default-popup popup-lacky popup-auth-special popup-promo popup-promo-lucky popup-promo-case popup-horiz popup-promo-horiz-wheele">
        <div className="default-popup-wrap">
          <div
            className="box-modal_close arcticmodal-close"
            onClick={_onClose}
          ></div>
          <div className="popup-promo-horiz">
            <div className="popup-promo-horiz-left">
              <div
                className="balance-title"
                dangerouslySetInnerHTML={{
                  __html: t('popup bonus tired of wheel_title'),
                }}
              ></div>
              <div className="auth-special-title-seconds">
                <div className="percent">+{props?.percent}%</div>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: t('popup bonus tired of wheel_desc'),
                  }}
                ></div>
              </div>
              <div className="popup-promo-disclaimer">
                {t('Offer is limited')}
              </div>
            </div>
            <div className="popup-promo-horiz-right">
              <div className="img">
                <img src={PopupImg} alt="" />
              </div>
            </div>
          </div>
          <div className="promo-content">
            <div className="balance-btns">
              <Link
                onClick={onClick}
                to="/pay"
                state={{
                  promoCode: props?.promoCode,
                  promoCodeTimeOut: props?.promoCodeTimeOut,
                }}
                className="btn-pay btn-modal-wheele ring3_popap"
              >
                <div className="top"></div>
                <div className="top-2"></div>
                <div className="bot"></div>
                <div className="bot-2"></div>
                <div className="shadow"></div>
                <div className="shadow-2"></div>
                <div className="text">{t('Deposit')}</div>
                <div className="timer">
                  (<CountDown timer={180} />)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
