import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useProfile } from '~components/hooks';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from '../hooks';

import { CountDown } from '~components/CountDown';
import { usePromo } from '~frontend/components/pay/payment-right/PromoCode';
import Modal from '../Modal';

import PopupPromoImg1 from '../../../scss/images/popup-promo-img-1.png';

export const PromoModal2: FunctionComponent = () => {
  const modalName = 'PromoModal2';
  const getProfile = useProfile();
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{
    promoCode: string;
    promoCodeTimeOut: number;
    percent: number;
  }>(modalName);
  const [, , setPromoCode] = usePromo();

  const _onClose = () => {
    setPromoCode(props?.promoCode, props?.promoCodeTimeOut);

    onClose();
  };

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={400} modalName={modalName}>
      <div className="box-modal default-popup popup-lacky popup-promo popup-promo-username">
        <div className="default-popup-wrap">
          <div className="balance-title">{getProfile.userName}, GL HF!</div>
          <div
            className="box-modal_close arcticmodal-close"
            onClick={_onClose}
          ></div>
          <div className="img">
            <img src={PopupPromoImg1} alt="" />
          </div>
          <div className="promo-text">
            <div className="text">
              {t('A personalised promo code just for you!')}
            </div>
            <div className="num">+{props?.percent}%</div>
          </div>
          <div className="balance-btns">
            <Link
              onClick={onClick}
              className="btn-pay btn-modal-limit popolnit_30 reg_popap"
              to="/pay"
              state={{
                promoCode: props?.promoCode,
                promoCodeTimeOut: props?.promoCodeTimeOut,
              }}
            >
              <div className="top"></div>
              <div className="top-2"></div>
              <div className="bot"></div>
              <div className="bot-2"></div>
              <div className="shadow"></div>
              <div className="shadow-2"></div>
              <div className="text">{t('Deposit')}</div>
              <div className="timer">
                (<CountDown timer={180} />)
              </div>
            </Link>
          </div>
          <div className="popup-promo-disclaimer">{t('Offer is limited')}</div>
        </div>
      </div>
    </Modal>
  );
};
