export default function Down(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.667969 4C0.667969 1.79086 2.45883 0 4.66797 0H12.668C14.8771 0 16.668 1.79086 16.668 4V12C16.668 14.2091 14.8771 16 12.668 16H4.66797C2.45883 16 0.667969 14.2091 0.667969 12V4Z"
        fill="#3957AA"
      />
      <path
        d="M12.9336 3.73438C13.1523 3.98047 13.1523 4.33594 12.9336 4.55469L6.5625 10.9258H9.84375C10.1445 10.9258 10.418 11.1719 10.418 11.5C10.418 11.8281 10.1445 12.0742 9.84375 12.0742H5.16797C4.83984 12.0742 4.59375 11.8281 4.59375 11.5V6.82422C4.59375 6.52344 4.83984 6.25 5.16797 6.25C5.49609 6.25 5.74219 6.52344 5.74219 6.82422V10.1055L12.1133 3.73438C12.332 3.51562 12.6875 3.51562 12.9336 3.73438Z"
        fill="white"
      />
    </svg>
  );
}
