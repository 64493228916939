import './language-modal.scss';
import Close from '../closeIcon';

import {
  useCallback,
  memo,
  MouseEventHandler,
  SyntheticEvent,
  FunctionComponent,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useClickAway, useToggle } from 'react-use';
import { useConfig } from '~frontend/components/hooks';
import CheckSecond from './check-second';

const langImage = {
  ru: require(`../../../scss/images/ru.svg`),
  en: require(`../../../scss/images/en.svg`),
  de: require(`../../../scss/images/de.svg`),
  tr: require(`../../../scss/images/tr.svg`),
  pl: require(`../../../scss/images/pl.svg`),
  pt: require(`../../../scss/images/pt.svg`),
  th: require(`../../../scss/images/th.svg`),
  hi: require(`../../../scss/images/hi.svg`),
};

interface LangProps {
  key: string;
  lang: string;
  setLanguage: MouseEventHandler<HTMLDivElement>;
  language: string;
}

const Lang: FunctionComponent<LangProps> = memo(
  ({ lang, setLanguage, language }) => {
    return (
      <div
        className={`mobile_language_modal_language ${
          language === lang ? 'mobile_language_modal_language_active' : ''
        }`}
        onClick={setLanguage}
        data-lang={lang}
      >
        <LazyLoadImage
          src={langImage[lang]}
          alt={`${language} flag`}
          width={24}
          height={24}
        />
        <div className="mobile_language_modal_language_name">{lang}</div>
        {language === lang && (
          <div className="mobile_language_modal_language_check">
            <CheckSecond />
          </div>
        )}
      </div>
      // <div
      //   data-lang={lang}
      //   className={`header__controls-lang-dropdown-item ${
      //     lang === language && 'active'
      //   }`}
      //   onClick={setLanguage}
      // >
      //   <LazyLoadImage className="lang-icon" src={langImage[lang]} alt="" />
      //   <span>{lang}</span>
      // </div>
    );
  },
);

const LanguageModal: FunctionComponent<any> = ({ onClose }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isClosing, setIsClosing] = useState(false);

  const ref = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { reFetchObservableQueries } = useApolloClient();

  const [, setCookie] = useCookies();
  const { i18n } = useTranslation();
  const getConfig = useConfig();
  const languages = i18n.languages;

  const [active, setActive] = useToggle(false);

  useClickAway(ref, () => {
    setActive(false);
  });

  useEffect(() => {
    setActive(false);
  }, [i18n.language, setActive]);

  const setLanguage = useCallback(
    (
      event: SyntheticEvent<HTMLDivElement>,
      { lang } = event.currentTarget.dataset,
    ) => {
      const language = i18n.language;
      const expires = new Date();
      expires.setDate(expires.getDate() + 14);
      setCookie('lang', lang, { expires: expires, path: '/' });

      if (getConfig?.seo.language !== 'ru') {
        return (window.location.href = _.replace(
          pathname,
          `/${language}`,
          `/${lang}`,
        ));
      }

      i18n.changeLanguage(lang);

      reFetchObservableQueries();

      if (_.replace(pathname, language, lang) !== pathname) {
        navigate(_.replace(pathname, `/${language}`, `/${lang}`));
      }
    },
    [
      i18n,
      setCookie,
      getConfig?.seo.language,
      reFetchObservableQueries,
      pathname,
      navigate,
    ],
  );

  const handleLanguageSelect = (code) => {
    setSelectedLanguage(code);
    // Здесь можно добавить логику смены языка в приложении
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 500); // Длительность анимации
  };

  return (
    <div className="mobile_language_modal" onClick={handleClose} ref={ref}>
      <div
        className={`mobile_language_modal_content ${
          isClosing ? 'closing' : ''
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mobile_language_modal_header">
          <div className="mobile_language_modal_title">Language</div>
          <div className="mobile_language_modal_close" onClick={handleClose}>
            <Close />
          </div>
        </div>
        <div className="mobile_language_modal_languages">
          {languages.map((lang) => (
            <Lang
              key={lang}
              setLanguage={setLanguage}
              language={i18n.language}
              lang={lang}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(LanguageModal);
