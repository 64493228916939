export default function Promocode({
  color = "white",
  width = "24",
  height = "24",
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5008_69590)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.58594 20.4141C1.96101 20.7891 2.46957 21 3 21H21C21.5304 21 22.039 20.7891 22.4141 20.4141C22.7891 20.039 23 19.5304 23 19V15C22.2044 15 21.4415 14.6837 20.8789 14.1211C20.3163 13.5585 20 12.7956 20 12C20.0016 11.2048 20.3181 10.4431 20.8804 9.88086C21.4426 9.31859 22.2048 9.00159 23 9V5C23 4.46957 22.7891 3.96101 22.4141 3.58594C22.039 3.21086 21.5304 3 21 3H3C2.46957 3 1.96101 3.21086 1.58594 3.58594C1.21086 3.96101 1 4.46957 1 5V9C1.79516 9.00159 2.55736 9.31859 3.11963 9.88086C3.68189 10.4431 3.99841 11.2048 4 12C4 12.7956 3.6837 13.5585 3.12109 14.1211C2.55848 14.6837 1.79565 15 1 15V19C1 19.5304 1.21086 20.039 1.58594 20.4141ZM5.18311 9.26074C4.65025 8.44708 3.89142 7.80604 3 7.41699V5H21V7.41992C20.1092 7.80981 19.3511 8.45019 18.8184 9.26367C18.2856 10.0771 18.0013 11.0286 18 12.001C18.001 12.9741 18.2852 13.9259 18.8179 14.7402C19.3506 15.5546 20.1088 16.1962 21 16.5869V19.002H3C2.99939 19 2.99939 18.998 3 18.9961V16.584C3.89128 16.1933 4.6494 15.5517 5.18213 14.7373C5.71486 13.9229 5.99914 12.9712 6 11.998C5.99969 11.0254 5.71596 10.0744 5.18311 9.26074ZM13.5859 13.5869C13.2109 13.962 13 14.4705 13 15.001C13 15.5314 13.2109 16.04 13.5859 16.415C13.961 16.7901 14.4696 17.001 15 17.001C15.5304 17.001 16.039 16.7901 16.4141 16.415C16.7891 16.04 17 15.5314 17 15.001C17 14.4705 16.7891 13.962 16.4141 13.5869C16.039 13.2118 15.5304 13.001 15 13.001C14.4696 13.001 13.961 13.2118 13.5859 13.5869ZM14.8301 7.75977L7.75684 14.8301L9.17188 16.2412L16.2451 9.17188L14.8301 7.75977ZM7.58594 7.58691C7.21086 7.96199 7 8.47054 7 9.00098C7 9.53141 7.21086 10.04 7.58594 10.415C7.96101 10.7901 8.46957 11.001 9 11.001C9.53043 11.001 10.039 10.7901 10.4141 10.415C10.7891 10.04 11 9.53141 11 9.00098C11 8.47054 10.7891 7.96199 10.4141 7.58691C10.039 7.21184 9.53043 7.00098 9 7.00098C8.46957 7.00098 7.96101 7.21184 7.58594 7.58691Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5008_69590">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
