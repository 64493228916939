import {
  memo,
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Button } from '~ui/button/button';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useQuery, gql, DocumentNode } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { HistoryInput as HistoryInputTypes, ActiveGames, Game } from '~types';

import { toast } from 'react-toastify';
import { useConfig } from '~components/hooks';
import Item from './Item';
import { SellAllItems } from '../index';
import playAudio from '~frontend/utils/playAudio';
import { Loader } from '../../Loader';
import IconEmpty from '../../../scss/images/icon-profile-empty.svg';
import { useToggle, useUpdateEffect } from 'react-use';
import { copyToClipboard } from '~frontend/utils/clickboard';
import './profileItemsStyles.scss';

export const FRAGMENT_ITEM_FILEDS = gql`
  fragment ItemFields on Item {
    id
    color
    getImage
    getName
    price
    isStatTrak
    quality
  }
`;

export const FRAGMENT_GAME_FILEDS = gql`
  fragment GameFields on Game {
    id
    status
    getStatus
    price
    priceSell
    userId
    chance
    type
    eventId
    battlePassId
    distributionGameId
    getItem {
      ...ItemFields
    }
    getCase {
      id
      getName
      getUrl
      getImage
      type
      seo
    }
  }

  ${FRAGMENT_ITEM_FILEDS}
`;

interface Types {
  query: DocumentNode;
  fragments: {
    games: DocumentNode;
  };
}

export const GET_HISTORY: Types = {
  query: gql`
    query GetHistory($input: HistoryInput!) {
      getHistory(input: $input) {
        count
        getGames {
          ...GameFields
        }
      }
    }

    ${FRAGMENT_GAME_FILEDS}
  `,
  fragments: {
    games: FRAGMENT_GAME_FILEDS,
  },
};

interface ItemsType {
  isUser?: boolean;
  userId: number;
  filters?: any;
}

interface ItemsData {
  getHistory: ActiveGames;
  getStats: Stats;
}

interface ItemsVar {
  input: HistoryInputTypes;
}

interface IItemsList {
  count: number;
  loading: boolean;
  isUser: boolean;
  getGames: Game[];
}

const ItemsList: FunctionComponent<IItemsList> = memo(
  ({ count, loading, getGames, isUser }) => {
    const { t } = useTranslation();
    const getHistory = useMemo(() => {
      return _.map(getGames, (getGame) => (
        <Item getGame={getGame} key={getGame?.id} isUser={isUser} />
      ));
    }, [getGames, isUser]);

    return (
      <div className="profile_items_grid">
        {loading ? (
          <Loader />
        ) : count === 0 ? (
          <div className="in-case-empty">
            <div className="empty">
              <img src={IconEmpty} alt="" />
              <div className="text">
                {t('Its time to beat out the top skins!')}
                <br />
                {t('For this, only')}
              </div>
              <Link className="btn btn-violet" to="/case/free">
                {t('Open free case')}
              </Link>
            </div>
          </div>
        ) : (
          <>{getHistory}</>
        )}
      </div>
    );
  },
);

export const Items: FunctionComponent<ItemsType> = memo(
  ({ isUser, userId, filters }: ItemsType) => {
    const [isAwait, toggle] = useToggle(false);
    const [isCopy, toggleCopy] = useToggle(false);
    const { t } = useTranslation();
    const getConfig = useConfig();
    const profileUrl = `https://${getConfig?.hostname}/user/${userId}`;

    const [originalGames, setOriginalGames] = useState<Game[]>([]);
    const [filteredGames, setFilteredGames] = useState<Game[]>([]);

    const [limit, setLimit] = useState(15);

    const {
      loading,
      error,
      data,
      fetchMore,
      networkStatus,
      refetch,
      variables,
    } = useQuery<ItemsData, ItemsVar>(GET_HISTORY.query, {
      notifyOnNetworkStatusChange: true,
      defaultOptions: {
        variables: {
          input: {
            offset: 0,
            limit,
            userId,
            isAwait,
          },
        },
      },
    });

    useUpdateEffect(() => {
      setLimit(15);
      refetch({
        input: {
          offset: 0,
          limit,
          userId,
          isAwait,
        },
      });
    }, [userId, isAwait]);

    const getGames = data?.getHistory?.getGames;
    const count = data?.getHistory?.count;
    const currentLength = _.size(getGames);

    const loadInv = async () => {
      try {
        const { data } = await fetchMore<ItemsData, ItemsVar>({
          variables: {
            input: {
              ...variables.input,
              offset: currentLength,
            },
          },
        });

        const getGames = data?.getHistory?.getGames;

        setLimit(currentLength + _.size(getGames));

        document.querySelector('.footer').scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      } catch (error) {
        toast.error(
          <>
            <div className="notify-title">{t('Error')}!</div>
            <div className="notify-text">{t(error.message)}</div>
          </>,
        );
      }
    };

    useEffect(() => {
      if (data?.getHistory?.getGames) {
        let games = data?.getHistory?.getGames;

        setOriginalGames(games);
        setFilteredGames(games);
      }
    }, [data]);

    // Логика фильтрации и сортировки на клиенте
    const applyFilters = useCallback(() => {
      if (!originalGames.length) return;

      let updatedGames = [...originalGames];

      // Фильтрация по типу предмета
      if (filters?.category && filters.category !== 'All') {
        updatedGames = updatedGames.filter(
          (game) =>
            game.getItem.getName.split('|')[0].trim().toLowerCase() ===
            filters.category.toLowerCase(),
        );
      }

      // Фильтрация по цене
      if (filters?.minPrice) {
        updatedGames = updatedGames.filter(
          (game) => game.price >= filters.minPrice * 100,
        );
      }

      if (filters?.maxPrice) {
        updatedGames = updatedGames.filter(
          (game) => game.price <= filters.maxPrice * 100,
        );
      }

      if (filters?.forSell) {
        updatedGames = updatedGames.filter((game) => game.status === 1);
      }

      //  Сортировка по цене
      if (filters?.sortOrder === 'Lowest price first') {
        updatedGames = updatedGames.sort((a, b) => a.price - b.price);
      } else if (filters?.sortOrder === 'Highest price first') {
        updatedGames = updatedGames.sort((a, b) => b.price - a.price);
      }

      setFilteredGames(updatedGames);
    }, [filters, originalGames]);

    useEffect(() => {
      applyFilters();
    }, [filters, originalGames]);

    const onClick = () => {
      copyToClipboard(`https://${getConfig?.hostname}/user/${userId}`);
      toggleCopy();

      setTimeout(() => {
        toggleCopy(false);
      }, 1000);
    };

    if (error) {
      return <>Items | Error! {error.message}</>;
    }

    return (
      <>
        <div
          className="profile_items"
          // style={{ display: 'flex', justifyContent: 'center' }}
        >
          {/* <span>{isUser ? t('User items') : t('Your items')}</span> */}
          {/* {!isUser && (
            <div className="btn-fast-open">
              <span
                dangerouslySetInnerHTML={{ __html: t('profile btn-fast-open') }}
              />
              <label className="switch" htmlFor="checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  defaultChecked={isAwait}
                  onChange={toggle}
                />
                <div className="switch-round"></div>
              </label>
            </div>
          )} */}
          {!isUser && <SellAllItems userId={userId} />}

          {isUser && (
            <>
              <div className="profile_heading_mobile">Предметы</div>
              {/* <div className="profile_heading_title">Предметы</div> */}
            </>
          )}
          {/* <div className="input-gradient input-copy" onClick={onClick}>
            <div className="icon material-icons" data-icon="&#xe157;"></div>
            <input type="text" disabled />
            <div className="fake-input">{profileUrl}</div>
            <div className="fake-label"></div>
            <div className="icon material-icons copy" data-icon="content_copy">
              {isCopy && <span>{t('Copied')}</span>}
            </div>
          </div> */}

          <ItemsList
            loading={loading && _.includes([2], networkStatus)}
            count={count}
            getGames={filteredGames}
            isUser={isUser}
          />
        </div>

        <div className="profile_load_more">
          {count > 15 && currentLength < count && (
            <Button
              data-audio="6.mp3"
              onMouseEnter={playAudio}
              //loading={loading}
              className={`btn1_dark_bordered profile_load_more_btn`}
              onClick={loadInv}
              text={!loading && <>{t('Show more')}</>}
            ></Button>
          )}
        </div>
      </>
    );
  },
);
