export default function Users({
  width = 16,
  height = 16,
  fill = "white",
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6562 7.34375C9.5625 7.34375 8.65625 6.4375 8.65625 5.34375C8.65625 4.21875 9.5625 3.34375 10.6562 3.34375C11.7812 3.34375 12.6562 4.21875 12.6562 5.34375C12.6562 6.4375 11.7812 7.34375 10.6562 7.34375ZM5.34375 7.34375C4.21875 7.34375 3.34375 6.4375 3.34375 5.34375C3.34375 4.21875 4.21875 3.34375 5.34375 3.34375C6.4375 3.34375 7.3125 4.21875 7.3125 5.34375C7.3125 6.4375 6.4375 7.34375 5.34375 7.34375ZM5.34375 8.65625C6.875 8.65625 10 9.4375 10 11V12C10 12.375 9.6875 12.6562 9.34375 12.6562H1.34375C0.96875 12.6562 0.65625 12.375 0.65625 12V11C0.65625 9.4375 3.78125 8.65625 5.34375 8.65625ZM10.6562 8.65625C12.2188 8.65625 15.3438 9.4375 15.3438 11V12C15.3438 12.375 15.0312 12.6562 14.6562 12.6562H11.2188C11.2812 12.4688 11.3438 12.2188 11.3438 12V11C11.3438 10.0312 10.8125 9.28125 10.0312 8.71875C10.0312 8.71875 10.0312 8.71875 10.0312 8.6875C10.25 8.6875 10.4688 8.65625 10.6562 8.65625Z"
        fill={fill}
      />
    </svg>
  );
}
