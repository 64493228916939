export default function Plus({ fill = "white" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 12.9844H12.9844V18C12.9844 18.5625 12.5625 18.9844 12 18.9844C11.4375 18.9844 11.0156 18.5625 11.0156 18V12.9844H6C5.4375 12.9844 5.01562 12.5625 5.01562 12C5.01562 11.4375 5.4375 11.0156 6 11.0156H11.0156V6C11.0156 5.4375 11.4375 5.01562 12 5.01562C12.5625 5.01562 12.9844 5.4375 12.9844 6V11.0156H18C18.5625 11.0156 18.9844 11.4375 18.9844 12C18.9844 12.5625 18.5625 12.9844 18 12.9844Z"
        fill={fill}
      />
    </svg>
  );
}
