export default function Up(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.332031 4C0.332031 1.79086 2.12289 0 4.33203 0H12.332C14.5412 0 16.332 1.79086 16.332 4V12C16.332 14.2091 14.5412 16 12.332 16H4.33203C2.12289 16 0.332031 14.2091 0.332031 12V4Z"
        fill="#089408"
      />
      <path
        d="M6.58203 4.5C6.58203 4.17188 6.85547 3.92578 7.15625 3.92578H11.832C12.1602 3.92578 12.4062 4.17188 12.4062 4.5V9.17578C12.4062 9.47656 12.1602 9.75 11.832 9.75C11.5039 9.75 11.2578 9.47656 11.2578 9.17578V5.89453L4.88672 12.2656C4.66797 12.4844 4.3125 12.4844 4.06641 12.2656C3.84766 12.0195 3.84766 11.6641 4.06641 11.4453L10.4375 5.07422H7.15625C6.85547 5.07422 6.58203 4.82812 6.58203 4.5Z"
        fill="white"
      />
    </svg>
  );
}
