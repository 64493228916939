export default function Upgrade(props) {
  return (
    <svg
      width="21"
      height="8"
      viewBox="0 0 21 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5156 0.015625C14.6406 0.015625 18.25 2.40625 19.9375 5.92188C20.2656 6.57812 19.8906 7.42188 19.1875 7.65625C18.625 7.84375 17.9688 7.5625 17.6875 7C16.4219 4.32812 13.6562 2.5 10.5156 2.5C8.54688 2.5 6.76562 3.20312 5.40625 4.375L7.28125 6.29688C7.9375 6.90625 7.46875 7.98438 6.57812 7.98438H1C0.4375 7.98438 0.015625 7.5625 0.015625 7V1.42188C0.015625 0.53125 1.09375 0.0625 1.70312 0.71875L3.57812 2.59375C5.45312 1 7.84375 0.015625 10.5156 0.015625Z"
        fill="white"
      />
    </svg>
  );
}
