export default function User({ width = 18, height = 18, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 9C6.79688 9 5.01562 7.21875 5.01562 5.01562C5.01562 2.8125 6.79688 0.984375 9 0.984375C11.2031 0.984375 12.9844 2.8125 12.9844 5.01562C12.9844 7.21875 11.2031 9 9 9ZM9 11.0156C11.6719 11.0156 17.0156 12.3281 17.0156 15V15.9844C17.0156 16.5469 16.5469 17.0156 15.9844 17.0156H2.01562C1.45312 17.0156 0.984375 16.5469 0.984375 15.9844V15C0.984375 12.3281 6.32812 11.0156 9 11.0156Z"
        fill="white"
      />
    </svg>
  );
}
