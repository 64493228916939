import styles from './styles.module.scss';
import { FunctionComponent } from 'react';
import './giveawaysModalStyles.scss';
import {
  useConfig,
  useGetDistributionGames,
  useProfile,
} from '~frontend/components/hooks';
import Modal from '../Modal';
import { useModal } from '../hooks';
import ModalWrapper from '../mobile-filter/modal-wrapper/modalWrapper';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { Distribution } from '~frontend/components/home/distributions/Distribution';
import { map, size } from 'lodash';

export const GiveawaysModal: FunctionComponent<any> = ({ giveaways }) => {
  const getProfile = useProfile();
  const getConfig = useConfig();
  const modalName = 'GiveawaysModal';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal(modalName);
  const { data, loading } = useGetDistributionGames({
    filterOrder: {
      sortField: 'type',
      sortOrder: 'ASC',
    },
  });

  if (loading) {
    return null;
  }

  const getDistributionGames = data?.getDistributionGames;

  if (size(getDistributionGames) === 0) {
    return null;
  }
  return (
    <Modal show={isOpen} onClose={onClose} width={440} modalName={modalName}>
      <ModalWrapper isOpen={isOpen} onClose={onClose} title={t('giveaways')}>
        <div className="giveaways_modal">
          {/*приписывается "1" к названию стиля чтобы избежать коллизий */}
          <div className="giveaways_list1">
            {/* {giveaways &&
              giveaways.map((gameMode, index) => (
                <GiveawaysItem
                  key={index}
                  {...gameMode}
                  className="giveaways_item_modal"
                  CustomIcon={CheckSecond}
                />
              ))} */}
            {map(getDistributionGames, (getDistributionGame) => (
              //Добавил пропс isModal чтобы менять width на 100% если гивы в модалке
              <Distribution
                key={getDistributionGame.id}
                getDistributionGame={getDistributionGame}
                isModal={true}
                onClose={onClose}
              />
            ))}
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  );
};
