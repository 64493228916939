// import styles from "./styles.module.scss";
// import Button from "@/app/components/ui/button";
import Wallet from './wallet';
import Coin from './coin';
import Plus from './plus';
import { FunctionComponent } from 'react';

import './profile.scss';
import { Button } from '../../../../../shared/frontend/ui/button/button';
import { useConfig } from '~frontend/components/hooks';
import Currency from '~frontend/components/Currency';

const BalanceSection: FunctionComponent<any> = ({
  balance = '0',
  coins = '0',
  className = '',
}) => {
  const getConfig = useConfig();
  return (
    <div className={`profile_stats_left ${className}`}>
      <div className="balance_block">
        <div className="balance_icon">
          <Wallet />
        </div>
        <div className="balance_amount">
          {balance} <Currency />
        </div>
        <Button
          text="Депозит"
          icon={<Plus fill="#482000" />}
          icon_direction="left"
          href={'/pay'}
          className={`btn1_yellow profile_stats_button profile_stats_button_deposit`}
        />
      </div>

      <div className="balance_devider"></div>

      {getConfig?.getBattlePass && (
        <div className="balance_block">
          <div className="balance_icon">
            <Coin />
          </div>
          <div className="balance_amount">{coins}</div>
          <Button
            text="Коины"
            icon={<Plus fill="white" />}
            icon_direction="left"
            className={`btn1_tab_bordered profile_stats_button profile_stats_button_coins}`}
          />
        </div>
      )}
    </div>
  );
};

export default BalanceSection;
