import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Currency from '~components/Currency';
import { useConfig, useGetActiveModal, useGetCase } from '~components/hooks';
import { Loader } from '~components/Loader';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { Price } from '~ui/index';
import { useModal } from '../hooks';

import { CountDown } from '~components/CountDown';
import { usePromo } from '~frontend/components/pay/payment-right/PromoCode';
import Modal from '../Modal';

const ModalView = ({ props, onClose, modalName }) => {
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { refetch } = useGetActiveModal();
  const getConfig = useConfig();

  const { loading, data } = useGetCase({ id: props?.caseId });
  const getCase = data?.getCase;

  useEffect(() => {
    refetch();
  }, [props?.caseId]);

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    onClose();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="box-modal default-popup popup-lacky popup-auth-special popup-promo popup-promo-lucky popup-promo-case popup-horiz">
      <div className="default-popup-wrap">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={onClose}
        ></div>
        <div className="popup-promo-horiz">
          <div className="popup-promo-horiz-left">
            <div className="balance-title">{t('popup double bonus_title')}</div>
            <div className="balance-title-sub">
              {t('popup double bonus_desc')}
            </div>
            <div className="auth-special-title">
              <div className="center">
                <span>
                  {t('popup double bonus_offer1', {
                    percent: props?.discount,
                    casename: getCase?.getName,
                  })}
                </span>
              </div>
            </div>
            <div className="auth-special-title-seconds">
              <div className="percent">+{props?.percent}%</div>
              <div className="text">
                {t('popup_free_case_title_second_text')}
              </div>
            </div>
          </div>
          <div className="popup-promo-horiz-right">
            <div className="img">
              <img src={getCase?.getImage} alt={getCase?.getName} />
            </div>
            <div className="popup-promo-case-cost">
              <div className="old">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getCase?.priceOld} />
                  </>
                ) : (
                  <>
                    <Price sum={getCase?.priceOld} />
                    <Currency />
                  </>
                )}
              </div>
              <div className="cost">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getCase?.price} />
                  </>
                ) : (
                  <>
                    <Price sum={getCase?.price} />
                    <Currency />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="promo-content">
          <div className="balance-btns">
            <Link
              onClick={onClick}
              to="/pay"
              state={{
                promoCode: props?.promoCode,
                promoCodeTimeOut: props?.promoCodeTimeOut,
              }}
              className="btn-pay btn-double-bonus ring4_popap"
            >
              <div className="top"></div>
              <div className="top-2"></div>
              <div className="bot"></div>
              <div className="bot-2"></div>
              <div className="shadow"></div>
              <div className="shadow-2"></div>
              <div className="text">{t('Deposit')}</div>
              <div className="timer">
                (<CountDown timer={180} />)
              </div>
            </Link>
          </div>
          <div className="popup-promo-disclaimer">{t('Offer is limited')}</div>
        </div>
      </div>
    </div>
  );
};

export const PromoModal9: FunctionComponent = () => {
  const modalName = 'PromoModal9';

  const { onClose, isOpen, props } = useModal<{
    promoCode: string;
    promoCodeTimeOut: number;
  }>(modalName);

  const [, , setPromoCode] = usePromo();

  const _onClose = () => {
    if (props?.promoCode) {
      setPromoCode(props?.promoCode, props?.promoCodeTimeOut);
    }

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={620} modalName={modalName}>
      <div className="box-modal default-popup popup-lacky popup-auth-special popup-promo popup-promo-lucky popup-promo-case">
        <ModalView props={props} onClose={_onClose} modalName={modalName} />
      </div>
    </Modal>
  );
};
