import { Image } from 'antd';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTimer } from '~components/hooks';
import { usePromo } from '~frontend/components/pay/payment-right/PromoCode';
import { useYaMetrica } from '~frontend/provider/yametrica';

import { Button } from '~shared/frontend/ui/button/button';
import Currency from '../Currency';
import Close from './closeIcon';
import { useModal } from './hooks';
import Modal from './Modal';
import "./wheelPrize.scss";

const CountDown = ({ endDate }: { endDate: string }) => {
  const timer = useTimer(endDate);

  return (
    <span>
      {timer?.hours}:{timer?.minutes}
      <span>:{timer?.seconds}</span>
    </span>
  );
};

export const WheelPrize: FunctionComponent = () => {
  const modalName = 'WheelPrize';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{
    promoCode?: string;
    promoCodeTimeOut?: number;
    endDate?: string;
    image?: string;
    caseId?: number;
    caseName?: string;
    caseUrl?: string;
    isCasePrize?: boolean;
  }>(modalName);
  const [, , setPromoCode] = usePromo();

  const _onClose = () => {
    if (props?.isCasePrize === false) {
      setPromoCode(props?.promoCode, props?.promoCodeTimeOut);
    }

    onClose();
  };

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    _onClose();
  };

  const openCase = () => {
    window.open(`${props.caseUrl}`);
    onClose();
  };

  return (
    <>
      {props?.isCasePrize ? (
        <Modal
          show={isOpen}
          onClose={onClose}
          width={364}
          modalName={modalName}
        >
          <div className="box-modal modal-blue modal-blue-not-wheel">
            <div className="box-modal_close arcticmodal-close">
              <div className="icon material-icons" onClick={onClose}>
                close
              </div>
            </div>
            <div className="modal-blue-title">{t('SuperPrize_yours')}</div>
            <div className="modal-blue-title-sec">
              {t('You_get_case')} "{`${props.caseName}`}" <br />
              {t('Fool_free')}
            </div>
            <div className="modal-blue-case">
              <img src={props.image} />
            </div>
            <div className="modal-blue-btn-wrap">
              <button className="btn-yellow btn-price" onClick={openCase}>
                <span className="text">{t('Open case')}</span>
                <span className="cost">
                  <Currency /> 0
                </span>
              </button>
            </div>
            <div className="disclaimer">{t('Promo_time')}</div>
          </div>
        </Modal>
      ) : (
        <Modal
          show={isOpen}
          onClose={onClose}
          width={364}
          modalName={modalName}
        >
          <div className="wheel_modal">
            <div className="wheel_modal_body">
              <div className="wheel_modal_header">
                <h2>{t(`${modalName} balance-title`)}</h2>
                <div 
                  onClick={onClose}
                  className="wheel_modal_close"
                >
                  <Close />
                </div>
              </div>
              <div className="wheel_modal_description">
                {t(`${modalName} popup-promo-disclaimer`)}
              </div>
              <div className="wheel_modal_prize">
                <Image
                  src={props?.image}
                  alt=""
                  width={300}
                  height={300}
                  preview={false}
                />
              </div>
              <Button
                text={t(`${modalName} btn`)}
                className="btn1_blue wheel_modal_btn"
                onClick={onClick}
                href="/pay"
                state={{
                  promoCode: props?.promoCode,
                  promoCodeTimeOut: props?.promoCodeTimeOut,
                }}
              />
              <div className="wheel_modal_text">
                <CountDown endDate={props?.endDate} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
