export default function Google(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5017_64211)">
        <path
          d="M26.6642 16.2418C26.6651 15.5148 26.6024 14.7892 26.4768 14.0732H16.2169V18.1802H22.0933C21.9705 18.8398 21.7145 19.4673 21.3409 20.0244C20.9672 20.5815 20.4838 21.0566 19.9202 21.4205V24.0864H23.424C24.4892 23.0831 25.3289 21.8649 25.8875 20.5124C26.4461 19.16 26.7109 17.7043 26.6642 16.2418Z"
          fill="#4285F4"
        />
        <path
          d="M16.2142 26.6626C18.8557 26.7394 21.4298 25.8208 23.4258 24.0888L19.922 21.4229C18.8154 22.1211 17.5267 22.4757 16.2187 22.4421C14.8695 22.4338 13.5564 22.0055 12.4618 21.2166C11.3672 20.4278 10.5455 19.3176 10.1108 18.0403H6.49353V20.7877C7.41773 22.5654 8.81383 24.0542 10.5286 25.0906C12.2434 26.1269 14.2106 26.6708 16.2142 26.6626Z"
          fill="#34A853"
        />
        <path
          d="M10.1072 18.0394C9.65182 16.7169 9.65182 15.2801 10.1072 13.9576V11.2109H6.49454C5.73324 12.6921 5.33612 14.3335 5.33612 15.9989C5.33612 17.6643 5.73324 19.3057 6.49454 20.7868L10.1072 18.0394Z"
          fill="#FBBC04"
        />
        <path
          d="M16.2142 9.55731C17.7598 9.53028 19.2558 10.1037 20.3874 11.1569L23.4928 8.11011C21.5077 6.29379 18.9046 5.30281 16.2142 5.3391C14.2109 5.33055 12.2439 5.87399 10.5292 6.9098C8.81443 7.94561 7.41814 9.43375 6.49353 11.2109L10.1062 13.9583C10.541 12.6812 11.3628 11.5712 12.4574 10.7825C13.552 9.99376 14.865 9.56555 16.2142 9.55731Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_5017_64211">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
