import './popularGamesStyles.scss';
import { FunctionComponent } from 'react';
import { gameModeData } from '~frontend/components/home/data/gameModeData';
import Modal from '../Modal';
import {
  useConfig,
  useGetDistributionGames,
  useProfile,
} from '~frontend/components/hooks';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal, useOpenModal } from '../hooks';
import User from './user';
import ModalWrapper from '../mobile-filter/modal-wrapper/modalWrapper';
import { useNavigate } from 'react-router-dom';

export const PopularModal: FunctionComponent = () => {
  const getProfile = useProfile();
  const getConfig = useConfig();
  const modalName = 'PopularModal';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal(modalName);
  const { onShow } = useOpenModal();
  const navigate = useNavigate();
  const { data, loading } = useGetDistributionGames({
    filterOrder: {
      sortField: 'type',
      sortOrder: 'ASC',
    },
  });

  const handleNavigateClick = (gameMode: any) => {
    if (gameMode.title === 'Free wheel') navigate('/wheel');
    if (gameMode.title === 'blog tag distributions') {
      onShow('GiveawaysModal');
      onClose();
    }
  };
  //Объект соответсвий подписанного стиля бэкграунда к названию стиля цвета
  const bgToColor = {
    'bg-game-1': 'purple',
    'bg-game-2': 'blue',
    'bg-game-3': 'red',
    'bg-game-4': 'yellow',
  };

  if (loading) {
    return null;
  }

  const getDistributionGames = data?.getDistributionGames;

  const usersInDistributionsCount = getDistributionGames.reduce((acc, game) => {
    acc += game.usersCount;
    return acc;
  }, 0);

  return (
    <Modal show={isOpen} onClose={onClose} width={440} modalName={modalName}>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={t('popular_modes')}
      >
        {' '}
        <div className="popular_modal">
          <div className="popular_wrapper">
            {gameModeData.map((gameMode) => {
              if (gameMode.title !== 'new_mode')
                return (
                  <div
                    className={`popular_item popular_item_${
                      bgToColor[gameMode.bg]
                    }`}
                    onClick={() => handleNavigateClick(gameMode)}
                    style={{
                      //Изменить прозрачность для неактивных и курсор
                      opacity: `${
                        gameMode.text === 'coming_soon' ? '48%' : '100%'
                      }`,
                      cursor: `${
                        gameMode.text === 'coming_soon' ? 'default' : 'pointer'
                      }`,
                    }}
                    key={gameMode.title}
                  >
                    <div className="popular_name">
                      {t(
                        `${
                          //Проверка чтобы в модалке показывался перевод "Колесо" а на главной "Колесо бонусов"
                          gameMode.title.includes('Free')
                            ? 'Bonus_Wheel'
                            : gameMode.title
                        }`,
                      )}
                    </div>
                    {gameMode.title === 'blog tag distributions' ? (
                      <div className="popular_value">
                        <User width={16} height={16} />{' '}
                        {usersInDistributionsCount}
                      </div>
                    ) : (
                      <div className="popular_value">
                        <User width={16} height={16} /> {gameMode.count}
                      </div>
                    )}
                    <img
                      src={gameMode.image}
                      alt={gameMode.title}
                      width={100}
                      height={100}
                      className="popular_image"
                    />
                  </div>
                );
            })}

            {/* <div className={`${styles.popular_item} ${styles.popular_item_blue}`}>
            <div className={styles.popular_name}>Викторина</div>
            <div className={styles.popular_value}>
              <User width={16} height={16} /> 8 452
            </div>
            <Image
              src="/images/popular-2.png"
              alt="popular-modal-2"
              width={100}
              height={100}
              className={styles.popular_image}
            />
          </div>

          <div className={`${styles.popular_item} ${styles.popular_item_red}`}>
            <div className={styles.popular_name}>VIP club</div>
            <div className={styles.popular_value}>
              <User width={16} height={16} /> 6 741
            </div>
            <Image
              src="/images/popular-3.png"
              alt="popular-modal-3"
              width={100}
              height={100}
              className={styles.popular_image}
            />
          </div>

          <div
            className={`${styles.popular_item} ${styles.popular_item_yellow}`}
          >
            <div className={styles.popular_name}>Раздачи</div>
            <div className={styles.popular_value}>
              <User width={16} height={16} /> 5 230
            </div>
            <Image
              src="/images/popular-4.png"
              alt="popular-modal-4"
              width={100}
              height={100}
              className={styles.popular_image}
            />
          </div> */}
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  );
};
