import { FunctionComponent, SyntheticEvent } from 'react';
import { useConfig } from '~components/hooks';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { useProfile } from '~components/hooks';
import { OpenCase as OpenCaseTypes } from '~types';
import { useTranslation } from 'react-i18next';
import { setTimeout } from 'isomorphic-timers-promises';

import Modal from './Modal';
import CloseIcon from './closeIcon';

import { GetName, SteamItemImage } from '../item';
import { Price } from '~ui/Price';
import Currency from '../Currency';
import { Link } from 'react-router-dom';
import playAudio from '~frontend/utils/playAudio';
import { AuthBlock } from '../AuthBlock';
import { Button } from '~shared/frontend/ui/button/button';

export const WinDemoModal: FunctionComponent = () => {
  const getProfile = useProfile();
  const getConfig = useConfig();
  const modalName = 'WinDemoModal';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{ getGame: OpenCaseTypes }>(
    modalName,
  );
  const openCase = props?.getGame;
  const getGame = openCase?.getGame;
  const getItem = getGame?.getItem;

  const reachGoal = (event: SyntheticEvent<HTMLElement>) => {
    yametrica.reachGoal(event.currentTarget.getAttribute('data-reachgoal'));
  };

  const onOpenCase = async (event: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    onClose();

    await setTimeout(500);

    const getButton = document.querySelector(
      'button[data-type="openCase"]',
    ) as HTMLElement;

    if (getButton) {
      getButton.click();
    }
  };

  const money = getProfile?.money || 0;

  return (
    <Modal show={isOpen} onClose={onClose} width={440} modalName={modalName}>
      <div className="demo_modal_body">
        <div className="demo_modal_header">
          <h2>Твой демо-выигрыш</h2>
          <div className="demo_modal_close" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="demo_modal_content">
          <div className="demo_modal_subtitle">Этот приз ты бы получил</div>
          <div className="demo_modal_prize">
            <div className="demo_modal_prize_header">
              <div className="demo_modal_prize_price">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getGame?.price} />
                  </>
                ) : (
                  <>
                    <Price sum={getGame?.price} />
                    <Currency />
                  </>
                )}
              </div>
              <div className="demo_modal_prize_percent">{getGame?.chance}</div>
            </div>
            <SteamItemImage
              alt="prize"
              url={getItem?.getImage}
              width={218}
              height={164}
              size="218x164"
              className="demo_modal_prize_image"
            />
            <div className="demo_modal_prize_info">
              <div className="demo_modal_prize_tag">
                {getItem?.getName.split('|')[0].trim()}
              </div>
              <div className="demo_modal_prize_name">
                <span>{getItem?.quality}</span> |{' '}
                {getItem?.getName.split('|')[1].trim()}
              </div>
            </div>
          </div>
          <Button
            className={`btn1_green demo_modal_button btn1_disabled`}
            text={`Продать за ${getGame?.getItem.price / 100}`}
            currencyComponent={<Currency />}
            type={'button'}
            disabled={true}
          />
          <div className="demo_modal_devider"></div>
          <div className="demo_modal_subtitle">
            Круто, а теперь выбей настоящий!
          </div>
          {!getProfile ? (
            <Button
              className={`btn1_yellow demo_modal_button`}
              text="Войти"
              href={'/auth/steam'}
              isOutterHref={true}
            />
          ) : getProfile.money < getGame?.getCase.price ? (
            <Button
              className={`btn1_yellow demo_modal_button`}
              text="Пополнить"
              href={'/pay'}
              isOutterHref={true}
            />
          ) : (
            <Button
              className={`btn1_yellow demo_modal_button`}
              text={t('Open')}
              type={'button'}
              onClick={onClose}
            />
          )}
          <div className="demo_modal_descr">
            <span>Внимание</span>
            Демо-режим является симуляцией игрового процесса.
          </div>
        </div>
      </div>
      {/* <div className="box-modal modal-blue modal-blue-demo-game win-demo-modal">
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="modal-blue-title">
          {t('win-demo-modal modal-blue-title')}
        </div>
        <div className="demo-prize">
          <div className="demo-prize-title">
            {t(
              !getProfile
                ? 'win-demo-modal demo-prize-title guest'
                : 'win-demo-modal demo-prize-title',
            )}
          </div>
          <div className="in-case-item status-4" data-rar={getItem?.color}>
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.price} />
                  <Currency />
                </>
              )}
            </div>
            <div className="in-case-item-wrap">
              <div className="img">
                <div className="in-case-item-bg" />
                <SteamItemImage url={getItem?.getImage} size="350x350" />
              </div>
              <div className="in-case-info">
                <div className="in-case-item-name">
                  <GetName getItem={getItem} type={0} />
                </div>
                <div className="in-case-item-desc">
                  <GetName getItem={getItem} type={1} />{' '}
                  {getItem?.quality && `- ${getItem?.quality}`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn-buy btn-icon disabled"> */}
      {/*
          Comment to hide bonuses
          <div className="btn-sale-cost-wrap">
            <span className="btn-sale-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.price} />
                  <Currency />
                </>
              )}
            </span>
          </div>
          */}
      {/* <span className="icon material-icons">call_made</span>
          <span className="btn-buy-text">
            {t('win-demo-modal btn-buy btn-icon btn-buy-text')}
          </span>
          <span className="btn-buy-cost">
            {getConfig.isEU ? (
              <>
                <Currency />
                <Price sum={getGame?.priceSell} />
              </>
            ) : (
              <>
                <Price sum={getGame?.priceSell} />
                <Currency />
              </>
            )}
          </span>
        </button>
        <hr />
        {!getProfile ||
          (money > getGame?.getCase?.price && (
            <div className="cool-text">
              {t(
                !getProfile
                  ? 'win-demo-modal cool-text guest'
                  : 'win-demo-modal cool-text',
              )}
            </div>
          ))}
        {getProfile && money > getGame?.getCase?.price ? (
          <button className="btn-buy btn-icon" onClick={onOpenCase}>
            <span className="btn-buy-text">{t('Open case')}</span>
            <span className="btn-buy-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.getCase?.price} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.getCase?.price} />
                  <Currency />
                </>
              )}
            </span>
          </button>
        ) : null}
        {getProfile && getGame?.getCase?.price > money ? (
          <div className="demo-warning">
            <span className="icon material-icons">campaign</span>
            <div className="demo-warning-title">
              {t('Just a little to open the case')}!
            </div>
            <div className="demo-warning-text">
              {t('By replenishing the account at least')}{' '}
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.getCase?.price - money} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.getCase?.price - money} />
                  <Currency />
                </>
              )}{' '}
              {t(
                'or by selling items for this amount, you can immediately open it',
              )}
              !
            </div>
          </div>
        ) : null}

        {!getProfile ? (
          <>
            <div className="demo-warning">
              <span className="icon material-icons">campaign</span>
              <div className="demo-warning-text">
                {t('win-demo-modal demo-warning demo-warning-text')}
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="open-cases-btns">
              <AuthBlock />
            </div>
          </>
        ) : (
          getProfile &&
          getGame?.getCase?.price > money && (
            <Link
              className="add-money btn-pay"
              to="/pay"
              onClick={reachGoal}
              data-audio="9.mp3"
              onMouseEnter={playAudio}
              data-reachgoal="add_funds_profile"
            >
              <div className="top"></div>
              <div className="top-2"></div>
              <div className="bot"></div>
              <div className="bot-2"></div>
              <div className="shadow"></div>
              <div className="shadow-2"></div>
              <div className="text">{t('Pay')}</div>
            </Link>
          )
        )}
      </div> */}
    </Modal>
  );
};
