import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import { Button } from '~ui/index';
import { useUpdateTradeUrl } from '../profile/hooks';
import { TradeInput } from '../TradeInput';
import { useModal } from './hooks';
import Modal from './Modal';

export const TradeUrlSave: FunctionComponent = () => {
  const modalName = 'TradeUrlSave';
  const { t } = useTranslation();

  const { onClose, isOpen, props } = useModal<{ tradeUrl: string }>(modalName);

  const [updateTradeUrl, { loading }] = useUpdateTradeUrl(props?.tradeUrl);

  const onSave = async () => {
    try {
      await updateTradeUrl();
      onClose();
      toast.info(
        <>
          <div className="notify-title">{t('success')}</div>
          <div className="notify-text">{t('Exchange link saved')}</div>
        </>,
      );
    } catch (error) {
      toast.error(
        <>
          <div className="notify-title">{t('Error')}!</div>
          <div className="notify-text">{t(error.message)}</div>
        </>,
      );
    }
  };

  return (
    <Modal show={isOpen} onClose={onClose} width={560} modalName={modalName}>
      <div className="box-modal modal-blue" data-modal={modalName}>
        <div className="modal-blue-title">
          {t('Confirm that your trade link is entered correctly')}
        </div>
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons" data-icon="&#xe5cd;"></div>
        </div>

        <div className="profile-trade">
          <TradeInput initialTradeUrl={props?.tradeUrl} readOnly />
          <a
            className="link-where"
            href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
            target="_blank"
            rel="noreferrer"
          >
            {t('Where to get?')}
          </a>
        </div>

        <Button className="btn-blue-sec" onClick={onSave} loading={loading}>
          {t('Link entered correctly')}
        </Button>
      </div>
    </Modal>
  );
};
