export default function Close({
  width = 24,
  height = 24,
  color = '#363744',
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.2812 5.71875C18.7031 6.09375 18.7031 6.75 18.2812 7.125L13.4062 12L18.2812 16.875C18.7031 17.2969 18.7031 17.9062 18.2812 18.2812C17.9062 18.7031 17.2969 18.7031 16.875 18.2812L12 13.4062L7.125 18.2812C6.70312 18.7031 6.09375 18.7031 5.71875 18.2812C5.29688 17.9062 5.29688 17.2969 5.71875 16.875L10.5938 12L5.71875 7.125C5.29688 6.70312 5.29688 6.09375 5.71875 5.71875C6.09375 5.29688 6.70312 5.29688 7.125 5.71875L12 10.5938L16.875 5.71875C17.2969 5.29688 17.9062 5.29688 18.2812 5.71875Z"
        fill={color}
      />
    </svg>
  );
}
