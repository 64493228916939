export default function Coin(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47716 2 2 6.47714 2 12C2 17.5228 6.47716 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47714 17.5228 2 12 2ZM19 12.0003C19 15.8663 15.866 19.0003 12 19.0003C8.13399 19.0003 4.99998 15.8663 4.99998 12.0003C4.99998 8.13433 8.13399 5.00032 12 5.00032C15.866 5.00032 19 8.13433 19 12.0003ZM17.25 12.0003C17.25 14.6017 15.358 16.7612 12.875 17.1777V15.5003L13.5688 15.5003C13.6848 15.5003 13.7961 15.4542 13.8781 15.3722L14.7531 14.4972C15.0287 14.2216 14.8335 13.7503 14.4438 13.7503H11.3062C11.1902 13.7503 11.0789 13.7042 10.9968 13.6222L10.3781 13.0035C10.2961 12.9214 10.25 12.8101 10.25 12.6941V11.3065C10.25 11.1905 10.2961 11.0792 10.3781 10.9972L10.9968 10.3785C11.0789 10.2964 11.1902 10.2503 11.3062 10.2503H14.4438C14.8335 10.2503 15.0287 9.77908 14.7531 9.50347L13.8781 8.62847C13.7961 8.54642 13.6848 8.50033 13.5688 8.50033L12.875 8.50033V6.82291C15.358 7.23948 17.25 9.39895 17.25 12.0003ZM11.125 15.5003V17.1777C8.64198 16.7612 6.74998 14.6017 6.74998 12.0003C6.74998 9.39895 8.64198 7.23948 11.125 6.82291V8.50033H10.4312C10.3152 8.50033 10.2039 8.54642 10.1218 8.62847L8.62812 10.1222C8.54608 10.2042 8.49998 10.3155 8.49998 10.4315V13.5691C8.49998 13.6851 8.54608 13.7964 8.62812 13.8785L10.1218 15.3722C10.2039 15.4542 10.3152 15.5003 10.4312 15.5003L11.125 15.5003Z"
        fill="white"
      />
    </svg>
  );
}
