export const initialState = {
  PrModal: { isOpen: false },
  OutItemModal: { isOpen: false },
  BannerModal: { isOpen: false },
  WinItemsModal: { isOpen: false },
  LackyModal: { isOpen: false },
  BonusModal: { isOpen: false },
  ForgotModal: { isOpen: false },
  WithdrawNone: { isOpen: false },
  TradeUrlSave: { isOpen: false },
  PhoneNumberModal: { isOpen: false },
  AuthSpecial: {
    isOpen: false,
  },
  PromoModal1: {
    isOpen: false,
  },
  PromoModal2: {
    isOpen: false,
  },
  PromoModal3: {
    isOpen: false,
  },
  PromoModal4: {
    isOpen: false,
  },
  PromoModal7: {
    isOpen: false,
  },
  PromoModal8: {
    isOpen: false,
  },
  PromoModal9: {
    isOpen: false,
  },
  PromoModal10: {
    isOpen: false,
  },
  PromoModal11: {
    isOpen: false,
  },
  PromoModal12: { isOpen: false },
  PromoModal13: { isOpen: false },
  WheelPrize: { isOpen: false },
  WinDemoModal: { isOpen: false },
  LuckyModalWin: { isOpen: false },
  PopularModal: { isOpen: false },
  GiveawaysModal: { isOpen: false },
  DistributionModal: { isOpen: false },
  ExitPopup: { isOpen: false },
  DistributionWinModal: { isOpen: false },
  DistributionUserWinModal: { isOpen: false },
};
